import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../../../../contexts";
import { apiCallRaw, errorToasterCatch, useApi } from "../../../../../api";
import { Box } from "../../../../../Components/Box";
import { Button, Buttons, Input, Label, Select } from "../../../../../Components/Form";
import ConfigureSquadsForm from "../../../fleets/ConfigureSquadsForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "../../../../../Components/Modal";
import styled from "styled-components";
import _ from "lodash";
import ShipSearch from "../../../../../Components/ShipSearch";
import { toast } from "react-toastify";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;

  div {
    padding: 10px;
  }
`;

const H2 = styled.h2`
  font-size: 1.5em;
  margin-bottom: 25px;

  svg {
    margin-right: 15px;
    font-size: 35px;
  }
`;


const ErrorSpan = styled.span`
    color: ${(props) => props.theme.colors.danger.color};
`

const ReconfigureFleetBtn = ({ fleetId }) => {
  const authContext = useContext(AuthContext);

  const [ open, setOpen ] = useState(false);
  const [ pending, isPending ] = useState(false);
  const [ editingConfig, setEditingConfig ] = useState(null);
  const [ unsavedName, setUnsavedName ] = useState(null);
  const [ currentConfig, setCurrentConfig ] = useState(null);
  const [ currentName, setCurrentName ] = useState(null);
  const [ fleet ] = useApi(`/api/fleet/info?character_id=${authContext?.current?.id}`, true);
  // const [ data ] = useApi('/api/categories');
  const [ config, refreshCurrentConfig ] = useApi(`/api/v2/fleets/${fleetId}/current_config`)
  const [ selectedShip, setSelectedShip ] = useState(false);
  const [_reset, resetSearch] = React.useState(0);
  const [selectedTag, setSelectedTag] = useState('new_to_er');

  useEffect(() => {
    if(config){
      setCurrentConfig(config.config);
      setCurrentName(config.name);
    }
  }, [config])

  const global = useMemo(() => {
    if(config){
      return config.global;
    } else {
      return false;
    }
  })

  const savedConfig = useMemo(() => {
    return currentConfig;
  }, [currentConfig]);

  useEffect(() => {
    setUnsavedName(currentName)
  }, [currentName, setUnsavedName]);

  useEffect(() => {
    setEditingConfig(currentConfig);
  }, [currentConfig, setEditingConfig]);

  const saved = useMemo(() => {
    return _.isEqual(savedConfig, editingConfig);
  }, [savedConfig, editingConfig])

  // Only fleet admins: Instructor/Leadership should see this
  if (!authContext?.access['fleet-invite']) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (pending) {
      return; // stop users from clicking this twice
    }
    isPending(true);

    let json = {
      boss_id: authContext.current.id,
      data: editingConfig,
      name: unsavedName
    };

    apiCallRaw(`/api/v2/fleets/${fleetId}/reconfigure`, {
      method: 'POST',
      json
    })
    .then(() => {
      toast.success("Fleet config saved!");
      refreshCurrentConfig();
    })
    .catch((e) => {
     errorToasterCatch(e);
    })
    .finally(() => {
      isPending(false)
    })
  }

  const handleAddShip = (e) => {
    let new_config = JSON.parse(JSON.stringify(editingConfig));
    e.preventDefault();
    if(new_config?.wings[0].squads[0].ships.find(ship => ship.id === selectedShip.id)){
      toast.error("Cannot add that ship as the first squad in the first wing already has it");
      return;
    }
    new_config?.wings[0].squads[0].ships.push(selectedShip);
    setSelectedShip(null);
    resetSearch((prev) => prev + 1);
    setEditingConfig(new_config);
  }

  const handleAddTag = (e) => {
    let new_config = JSON.parse(JSON.stringify(editingConfig));
    e.preventDefault();
    if(new_config?.wings[0].squads[0].tags.find(tag => tag === selectedTag)){
      toast.error("Cannot add that tag as the first squad in the first wing already has it");
      return;
    }
    new_config?.wings[0].squads[0].tags.push(selectedTag);
    setEditingConfig(new_config);
  }




  if(fleet === null){
    return null;
  }

  return (
    <>
      <Button variant="primary" onClick={_ => setOpen(true)}>
        Reconfigure
      </Button>

      <Modal open={open} setOpen={setOpen}>
        <Box style={{ minWidth: "1000px" }}>
          <H2>
            <FontAwesomeIcon fixedWidth icon={faTasks} />
            Reconfigure Fleet
          </H2>

          <div className={"flex"}>
            <div className={"w-2/3"}>

              <div className={"flex items-center"}>
                <Label htmlFor="config_name" required className={"mb-0"}>
                  Config Name:
                </Label>
                <Input
                  id={'config_name'}
                  type="text"
                  autoComplete="off"
                  className={"mx-2"}
                  value={unsavedName}
                  onChange={e => setUnsavedName(e.target.value)}
                />
                {!unsavedName && (
                  <ErrorSpan>Must have a value</ErrorSpan>
                )}
              </div>

              <Form onSubmit={handleAddShip}>
                <div className={"flex items-center"}>
                  <Label htmlFor="ship-search" required className={"mb-0"}>
                    Search for a ship:
                  </Label>
                  <ShipSearch
                    required
                    resetSearch={_reset}
                    style={{ width: "100%" }}
                    setSelectedShip={setSelectedShip}
                  />
                  <Button variant='primary' disabled={!selectedShip}>
                    Add Ship
                  </Button>
                </div>
              </Form>

              <Form onSubmit={handleAddTag}>
                <div className={"flex items-center"}>
                  <Label htmlFor="tag-search" required className={"mb-0"}>
                    Select Tag to add:
                  </Label>
                  <Select
                    value={selectedTag}
                    onChange={(evt) => setSelectedTag(evt.target.value)}
                    className={"mx-2"}
                  >
                    <option value={"new_to_er"}>
                      New to ER
                    </option>
                    <option value={"new_to_site"}>
                      New to site type
                    </option>
                    <option value={"elite"}>
                      Elites
                    </option>
                    <option value={"alts"}>
                      Alts
                    </option>
                    <option value={"scout"}>
                      Scouts
                    </option>
                    <option value={"default"}>
                      Default
                    </option>
                  </Select>
                  <Button variant="primary" disabled={!selectedTag}>
                    Add Tag
                  </Button>
                </div>
              </Form>
            </div>
          </div>


          <Form onSubmit={handleSubmit}>
            <ConfigureSquadsForm
              editingConfig={editingConfig}
              setEditingConfig={setEditingConfig}
            />
            <Buttons style={{ width: '100%' }}>
              <Button variant='primary' disabled={pending || saved || (currentName === unsavedName && global)}>
                {saved ? 'Saved!' : currentName === unsavedName ? global ? 'Cannot overwrite global' : 'Save and Overwrite' : 'Save as new'}
              </Button>

              <Button type="button" onClick={_ => setOpen(false)}>
                Cancel
              </Button>
            </Buttons>
          </Form>
        </Box>
      </Modal>
    </>
  )
}

export default ReconfigureFleetBtn;
