import React, { useEffect, useMemo } from "react";
import { Label, Select } from "../../../Components/Form";
import styled from "styled-components";
import { faCircleInfo, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Shield } from "../../../Components/Badge";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import {CSS} from '@dnd-kit/utilities';

const FormGroup = styled.div`
  flex-grow: 2;
  padding-bottom: 20px;
`;

const DOM = styled.div`
  width: 100%;
  flex-shrink: 10;
`;

const WingHeading = styled.div`
  border-color: ${(props) => props.theme.colors.accent2};
  background-color: ${(props) => props.theme.colors.background};
`

const SquadLI = styled.li `
    border-color: ${(props) => props.theme.colors.accent2};

    svg {
        &.warning{
            path {
                color: ${(props) => props.theme.colors.warning.color};
            }
        }

    }
`

const ShipDOM = styled.div`
  position: relative;
  text-align: center;
  width: 65px;

  img {
    display: block;
    width: 50px;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .name {
    display: block;
    color: ${(props) => props.theme.colors.accent4};
    font-size: smaller;
      &:hover {
          background:  ${(props) => props.theme.colors.background};
          z-index: 200;
      }
  }
`;

const TagDOM = styled.div`
  position: relative;
  text-align: center;
  width: 65px;

  div {
    display: flex;
    width: 45px;
    height: 45px;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
      &.new_to_er {
          background:  ${(props) => props.theme.colors.danger.color};
      }
      &.new_to_site {
          background:  ${(props) => props.theme.colors.warning.color};
      }
      &.elite {
          background:  ${(props) => props.theme.colors.success.color};
      }
      &.alts {
          background:  ${(props) => props.theme.colors.shields.red};
      }
      &.scout {
          background:  ${(props) => props.theme.colors.primary.color};
      }
      &.default {
          background:  ${(props) => props.theme.colors.accent3};
      }
  }

  .name {
    display: block;
    color: ${(props) => props.theme.colors.accent4};
    font-size: smaller;
      &:hover {
          background:  ${(props) => props.theme.colors.background};
          z-index: 200;
      }
  }
`;

const Droppable = styled.div`
    margin: 1px;
    width: 100%;
    min-height: 85px;
    &.isOver {
        margin: 0;
        border: 1px solid ${(props) => props.theme.colors.success.accent};
        border-radius: 10px;
        min-height: 87px;
    }
    
`

const Ship = ({squad_id, id, name}) => {

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: `ship-${id}-${squad_id}`
  })

  const style = transform ? {
    transform: CSS.Translate.toString(transform),
    zIndex: 250
  } : undefined

  return (
    <ShipDOM ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <img src={`https://images.evetech.net/types/${id}/render`} alt={name} />
      <span className="name truncate min-w-full hover:visible hover:relative hover:w-fit">{name}</span>
    </ShipDOM>
  )
}

const Tag = ({squad_id, tag}) => {

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: `tag-${tag}-${squad_id}`
  })

  const style = transform ? {
    transform: CSS.Translate.toString(transform),
    zIndex: 250
  } : undefined

  return (
    <TagDOM ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <div className={tag}>
        {tag === 'scout' && ('S')}
        {tag === 'alts' && ('A')}
        {tag === 'default' && ('D')}
        {tag === 'new_to_er' && (<FontAwesomeIcon fixedWidth className={'alert'} icon={faExclamationTriangle} />)}
        {tag === 'new_to_site' && (<FontAwesomeIcon fixedWidth className={'alert'} icon={faExclamationTriangle} />)}
        {tag === 'elite' && (    <Shield {...{ type: "shield", color: "neutral", letter: "E", name: "Elite" }} title={"Elite"} />)}

      </div>
      <span className="name truncate min-w-full hover:visible hover:relative hover:w-fit">
            {tag === 'scout' && ('Scout')}
        {tag === 'alts' && ('Alts')}
        {tag === 'default' && ('Default')}
        {tag === 'new_to_er' && ("New to ER")}
        {tag === 'new_to_site' && ("New to Site Type")}
        {tag === 'elite' && ("Elite")}
          </span>
    </TagDOM>
  )
}


const SquadSelectV3 = ({ squad_id, allSquads }) => {

  let squad = useMemo(() => {
    return allSquads?.find(squad => squad.id === squad_id);
  }, [allSquads]);

  const {isOver, setNodeRef} = useDroppable({
    id: squad_id
  })

  return (
    <Droppable className={`flex flex-wrap items-center p-0 ${isOver ? 'isOver' : ''}`} ref={setNodeRef}>
      {squad && squad.ships.map(({id, name}) => (
        <Ship id={id} name={name} squad_id={squad_id} key={id} />
      ))}
      {squad && squad.ships.length > 0 && squad.tags.length > 0 && (
        <span className="text-sm/6 py-0 h-fit ">AND</span>
      )}
      {squad && squad.tags.map((tag) => (
        <Tag tag={tag} key={tag} squad_id={squad_id} />
      ))}
    </Droppable>
  )
}

const ConfigureSquadsForm = ({ editingConfig, setEditingConfig }) => {

  const allSquads = useMemo(() => {
    return editingConfig?.wings.map(wing => wing.squads).flat();
  }, [editingConfig])

  function handleDragEnd(event) {
    let new_config = JSON.parse(JSON.stringify(editingConfig));
    const origin_data = event.active.id.split('-');
    const origin = parseInt(origin_data[2]);
    const ship_or_tag = origin_data[0];
    let object_id = origin_data[1];

    if(!event.over){
      if(ship_or_tag === 'ship'){
        object_id = parseInt(object_id)
        new_config?.wings.forEach(wing => {
          wing.squads.forEach(squad => {
            if(squad.id === origin){
              squad.ships = squad.ships.filter(ship => ship.id !== object_id)
            }
          })
        })
      }

      if(ship_or_tag === 'tag'){
        new_config?.wings.forEach(wing => {
          wing.squads.forEach(squad => {
            if(squad.id === origin){
              squad.tags = squad.tags.filter(tag => tag !== object_id)
            }
          })
        })
      }
    } else {
      const destination = event.over.id;

      if(destination === origin){
        return;
      }

      let data = {}
      let dest_squad = null;
      let origin_squad = null;

      if(ship_or_tag === 'ship'){
        object_id = parseInt(object_id)
        new_config?.wings.forEach(wing => {
          wing.squads.forEach(squad => {
            if(squad.id === origin){
              origin_squad = squad;
            } else if(squad.id === destination){
              dest_squad = squad;
            }
          })
        })

        if(dest_squad.ships.find(ship => ship.id === object_id)){
          return;
        }

        data = origin_squad.ships.find(ship => ship.id === object_id)
        origin_squad.ships = origin_squad.ships.filter(ship => ship.id !== object_id)
        if(data !== {}){
          dest_squad.ships.push(data);
        }
      }

      if(ship_or_tag === 'tag'){
        new_config?.wings.forEach(wing => {
          wing.squads.forEach(squad => {
            if(squad.id === origin){
              origin_squad = squad;
            } else if(squad.id === destination){
              dest_squad = squad;
            }
          })
        })

        if(dest_squad.tags.find(tag => tag === object_id)){
          return;
        }
        origin_squad.tags = origin_squad.tags.filter(tag => tag !== object_id)
        dest_squad.tags.push(object_id);
      }
    }


    setEditingConfig(new_config);
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <DOM>
        {editingConfig?.wings.map(({id, name, squads}) => (
          <div key={id} className="relative">
            <WingHeading
              className="sticky top-0 z-10 border-y px-3 py-1.5 text-md/6 font-semibold">
              <h3>Wing - {name}</h3>
            </WingHeading>
            <ul role="list" className="divide-y">
              {squads.sort((a,b) => a.id - b.id).map(({id, name, ships, tags}) => (
                <SquadLI key={id} className="flex px-3 items-center">
                  <p className="w-48 text-md/6 py-0 h-fit flex items-center">
                    {name}
                    {name.includes('Box') && ships?.length === 0 && tags?.length === 0 && (
                      <FontAwesomeIcon fixedWidth className={'warning pl-1'} icon={faCircleInfo} data-tooltip-id="tip" data-tooltip-html="This squad will be mapped as a boxer squad if left as Default" />
                    )}
                  </p>
                  <SquadSelectV3
                    squad_id={id}
                    editingConfig={editingConfig}
                    allSquads={allSquads}
                  />
                </SquadLI>
              ))}
            </ul>
          </div>
        ))}
      </DOM>
    </DndContext>
  )
}

export default ConfigureSquadsForm;
