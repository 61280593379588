import React from "react";
import { NavLink } from "react-router-dom";
import { PageTitle } from "../../Components/Page";
import { AuthContext } from "../../contexts";
import { Card, CardArray, CardMargin } from "../../Components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faUserShield,
  faBullhorn,
  faBan,
  faPoll,
  faCog, faChartLine, faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { usePageTitle } from "../../Util/title";

function GuideCard({ icon, slug, name, children, external }) {
  return (
    <CardMargin>
      <NavLink style={{ textDecoration: "inherit", color: "inherit" }} to={`${slug}`} reloadDocument={external}>
        <Card
          title={
            <>
              <FontAwesomeIcon fixedWidth icon={icon} /> {name}
            </>
          }
        >
          <p>{children}</p>
        </Card>
      </NavLink>
    </CardMargin>
  );
}

export function FCMenu() {
  const authContext = React.useContext(AuthContext);
  usePageTitle("FC Menu");
  return (
    <>
      <PageTitle>FC Dashboard</PageTitle>
      <CardArray>
        {authContext && authContext.access["waitlist-tag:HQ-FC"] && (
          <GuideCard slug="/fc/announcements" name="Announcements" icon={faBullhorn} />
        )}
        {authContext && authContext.access["bans-manage"] && (
          <GuideCard slug="/fc/bans" name="Bans" icon={faBan} />
        )}
        {authContext && authContext.access["badges-manage"] && (
          <GuideCard slug="/fc/badges" name="Badges" icon={faShieldAlt} />
        )}
        {authContext && authContext.access["commanders-view"] && (
          <GuideCard slug="/fc/commanders" name="Commanders" icon={faUserShield} />
        )}
        {authContext && authContext.access["stats-view"] && (
          <GuideCard slug="/fc/stats" name="Statistics" icon={faChartLine} />
        )}
        {authContext && authContext.access["reports-view"] && (
          <GuideCard slug="/fc/reports" name="Activity Reports" icon={faPoll} />
        )}
        {authContext && authContext.access["waitlist-tag:TRAINEE"] && (
          <GuideCard slug="/fc/configs" name="My Configs" icon={faCog} />
        )}
        {authContext && authContext.access["settings-view"] && (
          <GuideCard slug="/fc/settings" name="Waitlist Settings" icon={faCog} />
        )}
        {authContext && authContext.access["it-manage"] && (
          <>
            <GuideCard slug="/api/good_job" external={true} name="Background Job Monitor" icon={faCog} />
            <GuideCard slug="/api/avo" external={true} name="Backend Data Interface" icon={faCog} />
          </>
        )}
        {authContext && authContext.access["waitlist-tag:HQ-FC"] && (
          <GuideCard slug="/fc/status" name="ESI Status" icon={faQuestionCircle} />
        )}
      </CardArray>
    </>
  );
}
