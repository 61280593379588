import React from "react";
import { toast } from "react-toastify";

export function apiCallRaw(path, { json, ...options }){
  var requestOptions = {
    ...options,
  };

  if (!requestOptions.headers) {
    requestOptions.headers = {};
  }

  if (json) {
    if (!requestOptions.method) {
      requestOptions.method = "POST";
    }
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(json);
  }

  return fetch(path, requestOptions);
}
export async function apiCall(path, { json, ...options }) {
  var requestOptions = {
    ...options,
  };

  if (!requestOptions.headers) {
    requestOptions.headers = {};
  }

  if (json) {
    if (!requestOptions.method) {
      requestOptions.method = "POST";
    }
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(json);
  }

  const response = await fetch(path, requestOptions);

  var decoded;
  if ((response.headers.get("Content-Type") || "").match(/json/)) {
    decoded = await response.json();
  } else {
    decoded = await response.text();
  }

  if (response.status >= 400) {
    throw decoded;
  }
  return decoded;
}

export async function toaster(promise) {
  try {
    const result = await promise;
    toast.success(result)
  } catch (e) {
    console.error(e);
    toast.error(e.toString());
  }
}

export async function errorToaster(promise) {
  try {
    await promise;
  } catch (e) {
    console.error(e);
    toast.error(`Error: ${e.toString()}`);
  }
}

export function errorToasterCatch(error) {
  console.error(error);
  toast.error(`Error: ${error.toString()}`);
}

export function useApi(path, supressError = false) {
  const [data, setData] = React.useState(null);

  const refreshFunction = React.useCallback(() => {
    if (!path) return;

    errorToaster(
      apiCall(path, {}).then(setData, (err) => {
        setData(null);
        if(!supressError){
          throw err;
        }
      })
    );
  }, [path, supressError]);

  React.useEffect(() => {
    setData(null);
    refreshFunction();
  }, [refreshFunction]);

  return [data, refreshFunction];
}

export async function openWindow(target_id, character_id, target_type, inv_type = null) {
  return await apiCall(`/api/open_window`, {
    json: { target_id, character_id, target_type, inv_type },
  });
}
