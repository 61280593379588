import React from "react";
import { toast } from "react-toastify";

export function AuthLogout() {
  React.useEffect(() => {
    fetch("/api/auth/logout").then((response) => {
      // Force page refresh
      window.location.href = "/";
    });
  }, []);

  return <p>Logging out...</p>;
}

export async function processAuth(callback) {
  const whoamiRaw = await fetch("/api/auth/whoami");
  const body = await whoamiRaw.text();
  if(whoamiRaw.status === 502){
    callback({
      status: 502,
      current: null
    })
    return
  }
  if (whoamiRaw.status === 401) {
    const data = JSON.parse(body);
    toast.error(`You logged in with your alt, but your main character, ${data.main}, is missing access tokens. Please log in with your main character first.`);
    return
  }
  if (whoamiRaw.status !== 200 && whoamiRaw.status !== 403) {
    callback(null);
    return;
  }
  if (whoamiRaw.status === 403 && body === 'You need to login to access that page.') {
    callback(null);
    return;
  }
  const whoami = JSON.parse(body);
  var access = {};
  whoami.access.forEach((level) => {
    access[level] = true;
  });
  callback({
    ...whoami,
    current: whoami.characters[0],
    access: access,
  });
}
