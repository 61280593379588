import { useMemo, useContext, useState } from "react";
import { Content } from "../../Components/Page";
import { usePageTitle } from "../../Util/title";
import { Switch } from "../../Components/Form";
import { Card, Details } from "./fleets-management/Settings/components";
import { SettingsContext } from "../../Contexts/Settings";
import { apiCall, errorToaster } from "../../api";

export function WaitlistSettings() {
  usePageTitle("Waitlist Settings");
  const {settings} = useContext(SettingsContext);

  const [ pending, isPending ] = useState(false);

  const change_anti_gank = (e) => {
    if (pending) {
      return;
    }
    isPending(true);

    errorToaster(
      apiCall(`/api/v2/settings/anti_gank`, {
        method: 'POST',
        json: {
          setting: e
        }
      })
      .finally(() => isPending(false))
    );
  }

  const change_it_support = (e) => {
    if (pending) {
      return;
    }
    isPending(true);

    errorToaster(
        apiCall(`/api/v2/settings/it_support`, {
          method: 'POST',
          json: {
            setting: e
          }
        })
            .finally(() => isPending(false))
    );
  }

  const change_waitlist_testing = (e) => {
    if (pending) {
      return;
    }
    isPending(true);

    errorToaster(
      apiCall(`/api/v2/settings/waitlist_testing`, {
        method: 'POST',
        json: {
          setting: e
        }
      })
        .finally(() => isPending(false))
    );
  }

  let anti_gank_mode = useMemo(() => {
    return settings.anti_gank === 't';
  }, [settings])

  let it_support_mode = useMemo(() => {
    return settings.it_support === 't';
  }, [settings])

  let waitlist_testing_mode = useMemo(() => {
    return settings.waitlist_testing === 't';
  }, [settings])

  return (
    <Content>
      <h2>Waitlist Settings</h2>
      <Card>
        <div>
          <Switch checked={anti_gank_mode} onChange={change_anti_gank}/>
          <Details>
            <p>Anti-gank mode</p>
            <div>
              { anti_gank_mode ? "On" : "Off" }
            </div>
          </Details>
        </div>
      </Card>
      <Card>
        <div>
          <Switch checked={it_support_mode} onChange={change_it_support}/>
          <Details>
            <p>IT Support Mode</p>
            <div>
              { it_support_mode ? "On" : "Off" }
            </div>
          </Details>
        </div>
      </Card>
      <Card>
        <div>
          <Switch checked={waitlist_testing_mode} onChange={change_waitlist_testing}/>
          <Details>
            <p>Waitlist Testing Mode</p>
            <div>
              { waitlist_testing_mode ? "On" : "Off" }
            </div>
          </Details>
        </div>
      </Card>
    </Content>
  );
}
