import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { usePageTitle } from "../../Util/title";
import { apiCall, errorToaster, useApi } from "../../api";
import { CharacterName } from "../../Components/EntityLinks";
import Table, { TableControls } from "../../Components/DataTable";
import { Button, Buttons, CenteredButtons } from "../../Components/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTimes } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../contexts";
import { Modal } from "../../Components/Modal";
import { Box } from "../../Components/Box";
import { Title } from "../../Components/Page";

const Header = styled.div`
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;

  h1 {
    font-size: 32px;
  }
`;

const IconBtn = styled.span`
  [data-icon] {
    display: none;
  }
  @media (max-width: 450px) {
    [data-icon] {
      display: block !important;
    }
    span {
      display: none;
    }
  }
`;

const special_sort = (charA, charB) => {
  const a = charA?.name.toLowerCase();
  const b = charB?.name.toLowerCase();
  if (a > b) return 1;
  else if (b > a) return -1;
  else return 0;
};

const DeleteButton = ({id, refreshData}) => {
  const [pending, isPending] = useState(false);
  const onClick = () => {
    if (pending) {
      return; // stop users from clicking this twice
    }
    isPending(true);

    errorToaster(
      apiCall(`/api/v2/fleets/configs/${id}`, {
        method: "DELETE",
      })
        .then(() => {
          isPending(false);
          refreshData();
        })
        .catch((err) => {
          isPending(false);
          throw err;
        })
    );
  };

  return (
    <>
      <Button variant="danger" onClick={onClick}>
        <IconBtn>
          <FontAwesomeIcon fixedWidth icon={faUserTimes} />
          <span>Delete</span>
        </IconBtn>
      </Button>
    </>
  );
};

const ConfigsPage = () => {
  const [ configs, refreshData ] = useApi("/api/v2/fleets/configs");
  usePageTitle("My Configs");

  const columns = [
    {
      name: "Config",
      sortable: true,
      sortFunction: (rowA, rowB) => special_sort(rowA, rowB),
      grow: 2,
      selector: (row) => row.name
    },
    {
      name: "Site Type",
      sortFunction: (rowA, rowB) => special_sort(rowA, rowB),
      grow: 2,
      selector: (row) => row.site_type
    },
    {
      name: "",
      compact: true,
      grow: 1,
      minWidth: "46",
      selector: (row) =>
        <Buttons>
          <DeleteButton id={row.id} refreshData={refreshData} />
        </Buttons>
    },
  ]

  const TableHeader = useMemo(() => {
    return (
      <TableControls>
      </TableControls>
    )
  }, [])

  const filteredData = useMemo(() => {
    if(!configs){
      return [];
    } else {
      return configs.configs.filter(
        (row) =>
          row &&
          row.global === false
      )
    }
  }, [configs])

  return (
    <>
      <Header>
        <h1>My Fleet Configs</h1>
      </Header>

      <Table
        columns={columns}
        data={filteredData}
        subHeader
        subHeaderComponent={TableHeader}
        progressPending={!configs}
      />
    </>
  )
}

export default ConfigsPage;
