import React, { useContext, useState, useMemo, useEffect } from "react";
import { AuthContext } from "../../../contexts";
import { apiCallRaw, errorToasterCatch, useApi } from "../../../api";
import { Box } from "../../../Components/Box";
import { Button, Buttons, Input, Label, Select } from "../../../Components/Form";
import ConfigureSquadsForm from "./ConfigureSquadsForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "../../../Components/Modal";
import styled from "styled-components";
import { apiCall, errorToaster } from "../../../api";
import ShipSearch from "../../../Components/ShipSearch";
import { toast } from "react-toastify";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;

  div {
    padding: 10px;
  }
`;

const FormGroup = styled.div`
  flex-grow: 2;
  padding-bottom: 20px;
`;

const H2 = styled.h2`
  font-size: 1.5em;
  margin-bottom: 25px;

  svg {
    margin-right: 15px;
    font-size: 35px;
  }
`;

const ErrorSpan = styled.span`
    color: ${(props) => props.theme.colors.danger.color};
`

const RegisterFleetBtn = ({ refreshFunction }) => {
  const authContext = useContext(AuthContext);

  const [ selectedValue, setSelectedValue ] = useState('hq');
  const [ selectedConfig, setSelectedConfig ] = useState('default')
  const [ customConfigName, setCustomConfigName ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ pending, isPending ] = useState(false);
  const [ defaultMotd, isDefaultMotd ] = useState(true);
  const [ editingConfig, setEditingConfig ] = useState(null);
  const [ selectedShip, setSelectedShip ] = useState(false);
  const [_reset, resetSearch] = React.useState(0);
  const [selectedTag, setSelectedTag] = useState('new_to_er');
  const [ fleet ] = useApi(`/api/fleet/info?character_id=${authContext?.current?.id}`, true);
  const [ config_data, refreshConfigData ] = useApi(`/api/v2/fleets/configs?site_type=${selectedValue}`);
  const [ data ] = useApi('/api/categories');

  useMemo(() => {
    refreshConfigData();
  }, [selectedValue])

  let categories = useMemo(() => {
    return data?.categories;
  }, [data])

  useEffect(() => {
    let config = {
      wings: []
    }
    fleet?.wings.forEach(wing => {
      let wing_data = {
        id: wing.id,
        name: wing.name,
        squads: []
      }
      wing?.squads.forEach(squad => {
        wing_data.squads.push({
          id: squad.id,
          name: squad.name,
          ships: [],
          tags: []
        });
      });
      config.wings.push(wing_data)
    })

    config.wings.forEach(wing => {
      wing.squads.forEach(squad => {
        let found_option = categories.find(category => {
          if(category.auto_detect_names !== null && category.auto_detect_names !== undefined){
            return category.auto_detect_names.find((a) => squad?.name.toLowerCase().includes(a.toLowerCase()));
          } else {
            return squad?.name.toLowerCase().includes(category.name.toLowerCase())
          }
        });
        if(found_option){
          squad.ships = found_option.ships;
          squad.tags = found_option.tags || [];
        }
      });
    })

    setEditingConfig(config)
  }, [fleet, setEditingConfig, categories])

  let configs = useMemo(() => {
    if(config_data?.configs){
      return config_data.configs;
    } else {
      return []
    }
  }, [config_data])

  // Only fleet admins: Instructor/Leadership should see this
  if (!authContext?.access['fleet-invite']) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (pending) {
      return; // stop users from clicking this twice
    }
    isPending(true);

    let json = {
      default_motd: defaultMotd,
      boss_id: authContext.current.id,
      site_type: selectedValue,
      config: selectedConfig,
      custom_config: editingConfig,
      custom_config_name: customConfigName
    };

    apiCallRaw(`/api/v2/fleets`, {
      method: 'POST',
      json
    })
    .then((response) => {
      if (response.status >= 400) {
        throw response.text();
      }
      if ((response.headers.get("Content-Type") || "").match(/json/)) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .then((e) => {
      window.location.assign(e);
    })
    .catch((e) => {
      if(e instanceof Promise){
        e.then((e) =>{
          errorToasterCatch(e);
        })
      } else {
        errorToasterCatch(e.error);
      }
    })
    .finally(() => isPending(false))
  }

  const handleAddShip = (e) => {
    let new_config = JSON.parse(JSON.stringify(editingConfig));
    e.preventDefault();
    if(new_config?.wings[0].squads[0].ships.find(ship => ship.id === selectedShip.id)){
      toast.error("Cannot add that ship as the first squad in the first wing already has it");
      return;
    }
    new_config?.wings[0].squads[0].ships.push(selectedShip);
    setSelectedShip(null);
    resetSearch((prev) => prev + 1);
    setEditingConfig(new_config);
  }

  const handleAddTag = (e) => {
    let new_config = JSON.parse(JSON.stringify(editingConfig));
    e.preventDefault();
    if(new_config?.wings[0].squads[0].tags.find(tag => tag === selectedTag)){
      toast.error("Cannot add that tag as the first squad in the first wing already has it");
      return;
    }
    new_config?.wings[0].squads[0].tags.push(selectedTag);
    setEditingConfig(new_config);
  }

  const invalid = useMemo(() => {
    return selectedConfig === 'custom' && !customConfigName;
  }, [selectedConfig, customConfigName])


  return (
    <>
      <Button variant="primary" disabled={fleet === null} onClick={_ => setOpen(true)}>
        Register Fleet
      </Button>

      <Modal open={open} setOpen={setOpen}>
        <Box style={{ minWidth: "1000px" }}>
          <H2>
            <FontAwesomeIcon fixedWidth icon={faTasks} />
            Register Fleet
          </H2>

          <Form onSubmit={handleSubmit} className={'flex flex-col'}>
            <div className={'flex'}>
              <FormGroup>
                <Label htmlFor="site_type">
                  Site Type
                </Label>
                <Select value={selectedValue}  onChange={e => setSelectedValue(e.target.value)}>
                  <option value={'hq'}>Headquarters</option>
                  <option value={'kundi'}>Kundi Pop</option>
                  <option value={'assault'}>Assaults</option>
                  <option value={'vg'}>Vanguards</option>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="config">
                  Fleet Config
                </Label>
                <Select value={selectedConfig}  onChange={e => setSelectedConfig(e.target.value)}>
                  {!configs.find(config => config.name === 'Default') && (
                    <option value={"default"}>Default</option>
                  )}
                  {configs.map((config) => (
                    <option value={config.id} key={config.id}>{config.name}</option>
                  ))}
                  <option value={'custom'}>New Custom</option>
                </Select>
              </FormGroup>

              <FormGroup className={'flex, flex-col justify-evenly'}>
                <Label htmlFor="motd">
                  <input id="motd" type="checkbox" checked={defaultMotd} onChange={e => isDefaultMotd(!defaultMotd)} />
                  Use Default MOTD?
                </Label>
              </FormGroup>
            </div>


            {
            selectedConfig === 'custom' && <div>
                <div className={"flex"}>
                  <div className={"w-2/3"}>

                    <div className={"flex items-center"}>
                      <Label htmlFor="config_name" required className={"mb-0"}>
                        Config Name:
                      </Label>
                      <Input
                        id={'config_name'}
                        type="text"
                        autoComplete="off"
                        className={"mx-2"}
                        onChange={e => setCustomConfigName(e.target.value)}
                      />
                      {!customConfigName && (
                        <ErrorSpan>Must have a value</ErrorSpan>
                      )}
                    </div>

                    <div className={"flex items-center"}>
                      <Label htmlFor="ship-search" required className={"mb-0"}>
                        Search for a ship:
                      </Label>
                      <ShipSearch
                        required
                        resetSearch={_reset}
                        style={{ width: "100%" }}
                        setSelectedShip={setSelectedShip}
                      />
                      <Button variant="primary" disabled={!selectedShip} onClick={handleAddShip} type={"button"}>
                        Add Ship
                      </Button>
                    </div>

                    <div className={"flex items-center"}>
                      <Label htmlFor="tag-search" required className={"mb-0"}>
                        Select Tag to add:
                      </Label>
                      <Select
                        value={selectedTag}
                        onChange={(evt) => setSelectedTag(evt.target.value)}
                        className={"mx-2"}
                      >
                        <option value={"new_to_er"}>
                          New to ER
                        </option>
                        <option value={"new_to_site"}>
                          New to site type
                        </option>
                        <option value={"elite"}>
                          Elites
                        </option>
                        <option value={"alts"}>
                          Alts
                        </option>
                        <option value={"scout"}>
                          Scouts
                        </option>
                        <option value={"default"}>
                          Default
                        </option>
                      </Select>
                      <Button variant="primary" disabled={!selectedTag} onClick={handleAddTag}  type={"button"}>
                        Add Tag
                      </Button>
                    </div>
                  </div>
                </div>

                <ConfigureSquadsForm
                  editingConfig={editingConfig}
                  setEditingConfig={setEditingConfig}
                />
              </div>
            }

            <Buttons style={{ width: '100%' }}>
              <Button disabled={pending || invalid} variant='primary'>
                Submit
              </Button>

              <Button type="button" onClick={_ => setOpen(false)}>
                Cancel
              </Button>
            </Buttons>
          </Form>
        </Box>
      </Modal>
    </>
  )
}

export default RegisterFleetBtn;
